<template>
  <div id="map" :class="!$isAndroid?'map_con':'map_conIPAD'"></div>
</template>

<script>
export default {
  name: "MapComponent",
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.loadBaiduMapScript();
  },
  methods: {
    loadBaiduMapScript() {
      var map = new BMap.Map("map");
      var longitude = '113.385612';
      var latitude = '23.132561';
      map.centerAndZoom(new BMap.Point(longitude, latitude), 15);
      var marker = new BMap.Marker(new BMap.Point(longitude, latitude));
      marker.setAnimation(2);
      map.addOverlay(marker);
    },
  },
};
</script>

<style scoped lang='less'>
.map_con {
  width: 100%;
  height: 892px;
}
.map_conIPAD{
  width: 10rem;
  height: 4.63rem;
  background: #fff;
}
</style>