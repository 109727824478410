<template>
  <div>
    <Header navName="contact"></Header>
    <!-- PC端 -->
    <div class="contact_PC" v-if="!$isAndroid">
      <MapComponent></MapComponent>
      <div class="contactus_con" v-if="isShow">
        <div class="contactus_con_p">
          <img class="contactus_close" @click="isShow = false" src="@/static/contact/contact_close.png"
            alt="联系我们-极砚科技-创新科技,共研未来">
          <div class="contactus_con_top">
            <img src="@/static/contact/contactus_icon.jpg" alt="联系我们-极砚科技-创新科技,共研未来">
            欢迎新老客户及行业渠道携手合作共同探讨互联网升级新标准
          </div>
          <div class="contactus_con_mid">
            <div class="contactus_con_mid_l">
              <p class="title">加盟与业务合作</p>
              <div class="con">
                <img src="@/static/footer/weixin.jpg" alt="联系我们-极砚科技-创新科技,共研未来">
                <div>
                  <p>联系人：王先生</p>
                  <p>电话：15217695457</p>
                  <p>微信：15217695457</p>
                  <p>E-mail：1638802579@qq.com</p>
                </div>
              </div>
            </div>
            <div class="contactus_con_mid_l">
              <p class="title">联系我们</p>
              <div class="con">
                <div>
                  <p>地址：广州市天河区中山大道西491号二楼</p>
                  <p>邮编：510000</p>
                  <p>公司名：广州极砚商贸有限公司</p>
                  <p>信用代码：91440101MA9YA6MPXC</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact_H5" v-else>
      <MapComponent></MapComponent>
      <div class="center">欢迎新老客户及行业渠道携手合作<br>共同探讨互联网升级新标准</div>
      <div class="contactUs">
        <div class="contactUs_list">
          <div class="contactUs_list_top">
            <div class="shu"></div>
            <div class="title">加盟与业务合作</div>
          </div>
          <div class="contactUs_list_bottom">
            <div class="qrcode">
              <img src="@/static/footer/weixin.jpg" alt="联系我们-极砚科技-创新科技,共研未来">
            </div>
            <div class="contactUs_word">
              <p class="contactUs_item">联系人：王先生</p>
              <p class="contactUs_item">电话：15217695457</p>
              <p class="contactUs_item">微信：15217695457</p>
              <p class="contactUs_item">E-mail：1638802579@qq.com</p>
            </div>
          </div>
        </div>
        <div class="contactUs_list">
          <div class="contactUs_list_top">
            <div class="shu"></div>
            <div class="title">联系我们</div>
          </div>
          <div class="contactUs_list_bottom">
            <div class="contactUs_word">
              <p class="contactUs_item">地址：广州市天河区中山大道西491号二楼</p>
              <p class="contactUs_item">邮编：510000</p>
              <p class="contactUs_item">公司名：广州极砚商贸有限公司</p>
              <p class="contactUs_item">信用代码：91440101MA9YA6MPXC</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapComponent from '@/components/MapComponent'
export default {
  name: 'contact',
  components: {
    MapComponent
  },
  data() {
    return {
      isShow: true,
    }
  },
  mounted() {
    this.isShow = true;
  },
  methods: {},
}
</script>

<style scoped lang='less'>
.contact_PC {
  .contactus_con {
    width: 1200px;
    margin: 0 auto;

    .contactus_con_p {
      width: 1100px;
      height: 500px;
      background-color: #ffffff;
      box-shadow: 0px -1px 8px 0px rgba(51, 51, 51, 0.1);
      border-radius: 5px;
      position: absolute;
      top: 248px;
      left: 50%;
      transform: translate(-50%);
      transition: all .2s ease-out;

      &:hover {
        border-radius: 8px;
        box-shadow: 0 0 15px rgba(0, 0, 0, .2);
        top: 245px;

        .contactus_close {
          display: block;
        }
      }

      .contactus_close {
        position: absolute;
        width: 35px;
        right: 15px;
        top: 15px;
        display: none;
        cursor: pointer;
      }

      .contactus_con_top {
        width: 900px;
        height: 78px;
        font-size: 18px;
        line-height: 78px;
        border-bottom: solid 1px #e1e1e1;
        margin: 0 auto;
        margin-top: 41px;
        display: flex;

        img {
          margin-right: 20px;
          width: 21px;
          height: 17px;
          margin-top: 29px;
        }
      }


      .contactus_con_mid {
        display: flex;
        width: 900px;
        margin: 0 auto;
        margin-top: 55px;
        justify-content: space-between;

        .contactus_con_mid_l {
          width: 49.6%;

          .title {
            font-size: 18px;
            color: #222222;
          }

          .con {
            display: flex;
            margin-top: 15px;

            img {
              width: 120px;
              height: 118px;
            }

            div {
              margin-left: 19px;

              p {
                font-size: 16px;
                line-height: 30px;
                color: #666666;
              }
            }
          }
        }
      }
    }
  }
}

.contact_H5 {
  .center {
    font-weight: 300;
    line-height: .6rem;
    margin: .7rem 0;
    padding: 0 .4rem;
    box-sizing: border-box;
    font-size: .37rem;
    font-family: Microsoft YaHei;
    color: rgba(40, 40, 44, 1);
  }

  .contactUs {
    width: 10rem;
    height: 8.53rem;
    background: rgba(255, 255, 255, 1);
    padding: 1px 0;
    box-sizing: border-box;
    .contactUs_list {
      width: 100%;
      padding: 0 .4rem;
      box-sizing: border-box;

      .contactUs_list_top {
        margin: .67rem 0 .27rem;
        align-items: center;
        display: flex;

        .shu {
          width: .08rem;
          height: .48rem;
          background: rgba(11, 208, 192, 1);
          border-radius: .08rem;
          margin-right: .2rem
        }

        .title {
          font-weight: 700;
          line-height: .37rem;
          font-size: .37rem;
          font-family: Microsoft YaHei;
          color: rgba(40, 40, 44, 1);
        }
      }

      .contactUs_list_bottom {
        display: flex;
        align-items: center;

        .qrcode {
          width: 2.41rem;
          height: 2.4rem;
          margin-right: .24rem;

          img {
            width: 2.4rem;
            height: 2.4rem
          }
        }

        .contactUs_item {
          font-weight: 300;
          color: rgba(102, 102, 102, 1);
          margin-top: .26rem;
          font-size: .32rem;
          font-family: Microsoft YaHei;
          line-height: .32rem;

          &:nth-child(1) {
            margin-top: 0
          }
        }
      }
    }
  }
}
</style>